<template>
  <div>
    <el-popover
      placement="bottom"
      width="110"
      trigger="click"
      :visible-arrow="false"
      popper-class="accusal-popover"
    >
      <div
        class="text"
        @click="visible=true"
      >
        举报直播
      </div>
      <div
        slot="reference"
        class="mark"
      >
        <el-tooltip
          effect="dark"
          placement="bottom"
          content="举报"
          :visible-arrow="false"
          popper-class="share-tooltip default-tooltip"
        >
          <i class="iconfont icon-gengduobeifen" />
        </el-tooltip>
        <slot />
      </div>
    </el-popover>
    <el-dialog
      title="直播举报"
      width="640px"
      :visible.sync="visible"
      class="accusal-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="直播名称">
          <el-input
            v-model="form.name"
            disabled
            size="mini"
            class="form-item"
          />
        </el-form-item>
        <el-form-item
          label="投诉类型"
          prop="type"
        >
          <el-radio-group v-model="form.type">
            <template v-for="(type,index) in types">
              <el-radio
                :key="index"
                :label="type.value"
                class="form-item"
              >
                {{ type.desc }}
              </el-radio>
            </template>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="举报描述"
          prop="desc"
        >
          <el-input
            v-model="form.desc"
            type="textarea"
            class="form-item desc-input-wrapper"
            placeholder="请输入简短描述"
          />
          <div
            class="desc-count"
            :class="descLength>200 ? 'err-desc-count': ''"
          >
            {{ descLength }}/200
          </div>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="visible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="handleAccusal"
        >确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getReportType, reportRoom } from '@/services/room';

export default {
  name: 'AccusalLive',
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    roomId: {
      type: [Number, String],
      default: '',
    },
    roomName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      types: [],
      form: {
        name: this.roomName,
        type: '',
        desc: '',
      },
      rules: {
        type: [
          {
            required: true, message: '请选择投诉类型', trigger: 'change',
          },
        ],
        desc: [
          {
            min: 1, max: 200, message: '请输入200个字符以内的文字内容', trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    descLength() {
      return this.form.desc?.length || 0;
    },
  },
  async created() {
    this.types = await getReportType();
  },
  methods: {
    // 目前仅举报房间
    handleAccusal() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            await reportRoom({
              liveRoomId: this.roomId,
              liveRoomName: this.roomName,
              reportType: this.form.type,
              reportInfo: this.form.desc,
              type: 0,
            });
            this.$message.success('举报成功');
            this.visible = false;
          } finally {
            //
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .el-dialog__body {
    padding: 0 30px 40px;
  }

  .el-form-item__label {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333b44;
    line-height: 20px;
    float: initial;
  }

  .el-form-item__content {
    line-height: 0;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .form-item {
    margin-top: 10px;
  }

  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 82px;

    .form-item {
      display: block;
      margin-right: 108px;
    }

    .form-item:nth-of-type(3n) {
      margin-right: 0;
    }

    .form-item:nth-of-type(3n + 4) {
      margin-right: 150px;
    }

    .form-item:nth-of-type(1+3n) {
      margin-top: 20px;
      flex-grow: 1;
    }

    .form-item:nth-of-type(2+3n) {
      margin-top: 20px;
    }

    .form-item:nth-of-type(3+3n) {
      margin-top: 20px;
    }
  }

  .el-radio {
    //width: calc(100% / 3);
    margin-right: 0;
  }

  .el-radio__label {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333b44;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 0;
  }

  .el-textarea__inner {
    height: 68px;
    padding: 5px 8px;
  }

  .el-textarea .el-input__count {
    background: #FFF;
    position: absolute;
    bottom: -20px;
    right: 0;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #C1C4CB;
    text-align: right;
    line-height: 20px;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #959aa2;
  letter-spacing: 0;
  line-height: 20px;
}

.accusal-dialog {
  .el-dialog {
    width: 630px;
    height: 446px;
  }
}

.mark {
  margin-left: 12px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;

  .iconfont {
    font-size: 24px;
    color: #9699AC;
  }

  &:hover {
    background: #373946;

    .iconfont {
      color: #FFFFFF;
    }
  }
}

.desc-input-wrapper {
  position: relative;
}

.desc-count {
  position: absolute;
  right: 0;
  bottom: -20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #c1c4cb;
  text-align: right;
  line-height: 18px;
}

.err-desc-count {
  color: #EC333A;
}

</style>
<style lang="scss">
.accusal-popover {
  height: 42px;
  background: #373946;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.06);
  border-radius: 4px;
  border: 0;
  padding: 4px 0;
  min-width: 110px;
  transform: translate(0, -8px);

  .text {
    height: 34px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
    cursor: default;
    padding: 7px 9px;

    &:hover {
      background: rgba(21, 26, 40, 0.5);
    }
  }
}

.share-tooltip {
  transform: translate(0, -4px);
  background: #373946 !important;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  width: 48px;
  height: 24px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  // color: #FFFFFF;
  text-align: center;
  line-height: 24px;
  padding: 0;
}

.icon-gengduobeifen {
  cursor: pointer;
}
</style>
